import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components/macro'
import Layout from '../components/Layout'
import NavLink from '../components/NavLink'
import TypingAwayCoffee from '../img/TypingAwayCoffee_prog.jpg'

export default function Home() {
  return (
    <Layout pageTitle="Moin!">
      <Helmet>
        <meta
          name="google-site-verification"
          content="Rf43H9yPZqtEpi1g_vDmtmfEBkpEMh3lMkXE9v52HbU"
        />
      </Helmet>
      <HeroImageContainer>
        <main>
          <ContentContainer>
            <LandingpageText>
              <h2>Moin! </h2>
              <p>
                I am <span className="highlight">Rebecca,</span> <br />
                Web & Mobile Developer from Hamburg.
              </p>
              <div>
                <NavLink to="/contact">&#10172; Hire me</NavLink>
              </div>
            </LandingpageText>
          </ContentContainer>
        </main>
      </HeroImageContainer>
    </Layout>
  )
}

const ContentContainer = styled.div`
  display: grid;
  align-content: center;
  justify-content: center;
  justify-items: center;
`
const HeroImageContainer = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8)),
    url(${TypingAwayCoffee});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: relative;
`
const LandingpageText = styled.div`
  color: var(--secondary-light);
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);

  h2 {
    color: var(--secondary-light);
  }

  p {
    font-size: 2.4rem;
  }
`
